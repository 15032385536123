import React from 'react';
import ContactUs from './ContactUs';
import HeroSection from './HeroSection';

import './App.css';

function App() {
  return (
    <div className="App">
      <nav className="App-navbar">
        <strong style={{ fontSize: 18 }}>CivAPI</strong>
      </nav>
      <main>
        <HeroSection />
        <ContactUs />
      </main>
    </div>
  );
}

export default App;

import React from 'react';
import MonitoringHeader from '../MonitoringHeader';
import './index.css';
import worldMap from './world-map.svg';


const HeroSection = () => {
  return (
    <section className="hero-section">
      <div className="hero-column left-column">
        <MonitoringHeader />
        <p style={{ fontSize: 18, padding: 0 }}>
          Your reliable API provider for all civil data.
        </p>
      </div>
      <div className="hero-column" style={{ }}>
        <img src={worldMap} alt="Civil API" style={{ width: 500 }} />
      </div>
    </section>
  );
};

export default HeroSection;

import React from 'react';
import './index.css';

const ContactUs = () => {
  return (
    <div className="App-contact">
      <h2 style={{ fontSize: 32 }}>Contact Us</h2>
      <p>Have a use case? Feel free to contact us.</p>
      <p>Phone: <a href="tel:+359879459879">+359 879 459 879</a></p>
    </div>
  );
};

export default ContactUs;

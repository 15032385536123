import React, { useState, useEffect } from 'react'
import './index.css'

const words = ["casualties", "protests", "civil uprisings"]
const intervalTime = 1500

function MonitoringHeader() {
  const [currentWordIndex, setCurrentWordIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length)
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  return (
    <h1 style={{ textAlign: 'left', fontSize: 50, marginBottom: 0 }}>
      Monitoring<br />
      <span className="swipe-animation" style={{ color: '#FF6347', lineHeight: 1, height: 100 }}>
        {words[currentWordIndex]}
      </span>
    </h1>
  )
}

export default MonitoringHeader
